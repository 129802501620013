import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Course = () => {
    const { slug } = useParams(); // Get the slug from the URL
    const [course, setCourse] = useState(null);
    const [outline, setOutline] = useState([]); // To store the outline topics
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                // Fetch the main course details
                const response = await fetch(`https://admin.coderecordz.online/api/course/${slug}`);
                const courseData = await response.json();
                setCourse(courseData);
                // Fetch the course outline
                const outlineResponse = await fetch(`https://admin.coderecordz.online/api/course/outline/${courseData.course.id}`);
                const outlineData = await outlineResponse.json();
                console.log(`https://admin.coderecordz.online/api/course/outline/${courseData.course.id}`);
                setOutline(outlineData);
                console.log("Details For Outline:",outlineResponse);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching course data:", error);
                setLoading(false);
            }
        };
        fetchCourse();
    }, [slug]); // Re-fetch when the slug changes

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!course) {
        return <div>Course not found!</div>;
    }

    return (
        <div>
            <div className="jumbotron bg-danger">
                <div className="container text-white p-5">
                    <div className="row">
                        <div className="col-lg-9">
                            <h2><i className="fa fa-desktop"></i> {course.course.title}</h2>
                            <p>{course.course.description}</p>
                            <button className="btn btn-primary m-1"><i className="fa fa-share"></i> Share</button>
                            <a href={`https://admin.coderecordz.online/storage/app/public/pdfs/${course.course.pdf}`}>
                                <button className="btn btn-warning"><i className="fa fa-download"></i> Download Outline</button>
                            </a>
                        </div>
                        <div className="col-lg-3">
                            <img
                                src={"https://admin.coderecordz.online/storage/app/public/thumbnails/" + course.course.thumbnail || "https://via.placeholder.com/300x200"}
                                alt={course.title}
                                className="img-thumbnail"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Right Column - Course Schedule, Enrolled Students, and Other Info */}
            <div className="container mt-2 mb-5">
                <div className="row">
                    <div className="col-lg-8 col-sm-8 col-md-8">
                        {/* Lectures Card (Left side) */}
                        <div className="card">
                            <div className="card-header bg-danger text-white">
                                <i className="fa fa-play-circle"></i> All Topics
                            </div>
                            <div className="list-group">
                                {outline.length > 0 ? (
                                    outline.map((topic, index) => (
                                        <li key={index} className="list-group-item">
                                            <span className="badge bg-danger">{index+1}</span> {topic.title || "Untitled Topic"}
                                        </li>
                                    ))
                                ) : (
                                    <li className="list-group-item text-center text-muted">No topics added yet.</li>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-4 col-md-4">
                        {/* Course Schedule & Stats (Right side) */}
                        <div className="card">
                            <div className="card-header bg-danger text-white">
                                <i className="fa fa-info-circle"></i> Course Schedule
                            </div>
                            <ul className="list-group border-radius-0">
                                <li className="list-group-item" aria-current="true">
                                    <i className="fa fa-play-circle"></i> Lectures
                                    <span className="float-end">{course.course.lectures || "N/A"}</span>
                                </li>
                                <li className="list-group-item" aria-current="true">
                                    <i className="fa fa-file"></i> Sections
                                    <span className="float-end">{course.course.sections || "N/A"}</span>
                                </li>
                                <li className="list-group-item" aria-current="true">
                                    <i className="fa fa-calendar"></i> Upcoming Starting Batch
                                    <span className="float-end">{course.starting_batch || "N/A"}</span>
                                </li>
                                <li className="list-group-item" aria-current="true">
                                    <i className="fa fa-user-plus"></i> Enrolled Students
                                    <span className="float-end">{course.enrolled_students || "N/A"}</span>
                                </li>
                                <li className="list-group-item" aria-current="true">
                                    <i className="fa fa-list"></i> Total Batches
                                    <span className="float-end">{course.total_batches || "N/A"}</span>
                                </li>
                                <li className="list-group-item" aria-current="true">
                                    <i className="fa fa-users"></i> Trainers
                                    <span className="float-end">{course.trainers_count || "N/A"}</span>
                                </li>
                                <li className="list-group-item" aria-current="true">
                                    <i className="fa fa-money"></i> Current Fee
                                    <span className="float-end">{course.fee || "N/A"}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="card mt-2">
                            <div className="card-header bg-danger text-white">
                                <i className="fa fa-info-circle"></i> Why CodeRecordz?
                            </div>
                            <div className="card-body">
                                <p>
                                    Learning web development opens doors to endless opportunities in the tech world.
                                    By mastering languages like HTML, CSS, JavaScript, and frameworks such as React and Angular,
                                    you gain the ability to create dynamic, interactive websites. With the ever-growing demand
                                    for skilled developers, web development offers high-paying job prospects and the chance to work on exciting projects.
                                    Whether you're interested in front-end, back-end, or full-stack development, there's a career path suited to your skills.
                                    In this course, you'll gain hands-on experience and build projects that will set you on the path to becoming a professional web developer.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Course;
